// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import { readonly } from "vue";

// const app = {

// }


const words = readonly(["TOUGH", "GOOD", "WISE", "BOLD", "QUICK", "FRESH", "SMART", "FAST", "BRAVE", "PROUD", "SHARP", "BRIGHT"]);

const countries = readonly([
    "COTE D'IVOIRE",
    "DJIBOUTI",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "The Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Côte d’Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "The Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Sudan, South",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
]);

const shufflePatterns = readonly([
    ["col-span-2 row-span-2", "", "col-span-5 row-span-5", "", "col-span-3 row-span-3", "col-span-2 row-span-2", "col-span-3 row-span-3", "col-span-3 row-span-3", "", ""],
    ["col-span-3 row-span-3", "col-span-2 row-span-2", "col-span-3 row-span-3", "", "", "col-span-5 row-span-5", "col-span-3 row-span-3", "col-span-2 row-span-2", "", ""],
    ["col-span-2 row-span-2", "", "col-span-5 row-span-5", "", "col-span-3 row-span-3", "col-span-3 row-span-3", "col-span-3 row-span-3", "", "", "col-span-2 row-span-2"],
    ["col-span-2 row-span-2", "", "col-span-5 row-span-5", "", "col-span-3 row-span-3", "col-span-2 row-span-2", "col-span-3 row-span-3", "col-span-3 row-span-3", "", ""],
    ["col-span-3 row-span-3", "col-span-2 row-span-2", "col-span-3 row-span-3", "", "", "col-span-5 row-span-5", "col-span-3 row-span-3", "", "col-span-2 row-span-2", ""],
    ["col-span-3 row-span-3", "col-span-2 row-span-2", "col-span-3 row-span-3", "", "", "col-span-5 row-span-5", "col-span-3 row-span-3", "col-span-2 row-span-2", "", ""],
    ["col-span-2 row-span-2", "", "col-span-5 row-span-5", "", "col-span-3 row-span-3", "col-span-3 row-span-3", "col-span-3 row-span-3", "", "", "col-span-2 row-span-2"],
    ["col-span-3 row-span-3", "col-span-2 row-span-2", "col-span-3 row-span-3", "", "", "col-span-5 row-span-5", "col-span-3 row-span-3", "col-span-2 row-span-2", "", ""],
])

const artworks = readonly(Array(10).fill('').map((_, index) => `https://api.zeromalaria.org/images/artwork_small/en/${index+1}.jpg`));
// ? Large artworks for big tiles
const artworksLarge = readonly(Array(10).fill('').map((_, index) => `https://api.zeromalaria.org/images/artwork_large/en/${index+1}.jpg`));
const fillers = readonly(Array(7).fill('').map((_, index) => `https://api.zeromalaria.org/images/fillers/${index+1}.jpg`));


const messagePatternOptions = readonly([
    {
        // Center
        name: "center",
        corners: ["tl", "tr", "bl", "br"],

        html: `
              <span class="hover:bg-gray-400"></span>
              <span class="hover:bg-gray-400"></span>
              <!--  -->
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-green-500"></span>
              <!--  -->
              <span class="hover:bg-gray-400"></span>
              <span class="hover:bg-gray-400"></span>
          `,
    },
    {
        // Top left, bottom right
        name: "tl-br",
        corners: ["tl", "tr", "bl", "br", "BR"],

        html: `<span class="bg-green-500"></span>
              <span class="hover:bg-gray-400"></span>
              <!--  -->
              <span class="hover:bg-gray-400"></span>
              <span class="bg-white"></span>`,
    },
    {
        // Bottom right, top right
        name: "br-tr",
        corners: ["tl", "tr", "TR", "bl", "br"],

        html: `<span class="hover:bg-gray-400"></span>
              <span class="bg-white"></span>
              <!--  -->
              <span class="hover:bg-gray-400"></span>
              <span class="bg-green-500"></span>`,
    },
    {
        // Bottom right, top right
        name: "tl-bl",
        corners: ["tl", "tr","BL", "bl", "br"],
        html: `<span class="bg-green-500"></span>
              <span class="hover:bg-gray-400"></span>
              <!--  -->
              <span class="bg-white"></span>
              <span class="hover:bg-gray-400"></span>`,
    },
    {
        // Bottom left, top right
        name: "bl-tr",
        corners: ["tl", "tr", "TR", "bl", "br"],
        html: `<span class="hover:bg-gray-400"></span>
              <span class="bg-white"></span>
              <!--  -->
              <span class="bg-green-500"></span>
              <span class="hover:bg-gray-400"></span>`,
    },
    {
        // Top right, bottom left
        name: "tr-bl",
        corners: ["tl", "tr", "BL", "bl","br"],
        html: `<span class="hover:bg-gray-400"></span>
              <span class="bg-green-500"></span>
              <!--  -->
              <span class="bg-white"></span>
              <span class="hover:bg-gray-400"></span>`,
    },
]);

export default {
    artworks, // readonly
    artworksLarge, // readonly
    countries, // readonly
    fillers, // readonly
    patterns: shufflePatterns,
    patternOptions: messagePatternOptions,
    words, // readonly
};
