import { createRouter, createWebHistory } from 'vue-router'
// 
// 
// import Home from '../views/Home.vue'
import Welcome from '../views/Welcome.vue'
import Amortissement from '../views/Amortissement.vue'
import AmortissementFinancier from '../views/AmortissementFinancier.vue'
import ZeroMalariaLayout from '../views/layouts/ZeroMalariaLayout.vue'
import CreateMessage from '../views/apps/clones/zeromalaria/CreateMessage.vue';

// const zeroMalariaRoute = '../views/apps/clones/zeromalaria/'

const routes = [
    {
        path: "/",
        // name: "Home",
        component: Welcome,
    },
    {
        path: "/welcome",
        name: "Welcome",
        component: Welcome,
    },
    {
        path: "/t/amortissement",
        name: "Amortissement",
        component: Amortissement,
    },
    {
        path: "/y/amortissement",
        name: "AmortissementFinancier",
        component: AmortissementFinancier,
    },
    {
        path: "/zeromalaria",
        name: "ZeroMalaria",
        component: ZeroMalariaLayout,
        redirect: { name: "Explore" },
        children: [
            {
                path: "",
                redirect: { name: "Explore" },
            },
            {
                path: "/zeromalaria/explore",
                name: "Explore",
                props: (route) => ({ id: route.query.id }),
                component: function () {
                    return import(/* webpackChunkName: "about" */ "../views/apps/clones/zeromalaria/Explore.vue");
                },
            },
            {
                path: "/zeromalaria/create-message",
                name: "CreateMessage",
                component: CreateMessage,
                // component: function () {
                //     return import(/* webpackChunkName: "about" */ "../views/apps/clones/zeromalaria/CreateMessage.vue");
                // },
            },
        ],
    },
    

    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import(/* webpackChunkName: "about" */ "../views/About.vue");
        },
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
