<template>
    <div class="fixed overflow-y-scroll md:overflow-y-hidden top-0 bottom-0 w-full h-full grid md:grid-cols-12 bg-gray-900">
        <!-- Pattern schema -->
        <transition name="fade-in">
            <div class="md:col-span-7 xl:col-span-8 uppercase font-black md:text-xl flex flex-col justify-center items-center bg-gray-900 pt-2 px-2 md:pt-0 md:px-0">
                <MessagePatternGrid
                    v-show="!imagePatternDataUrl"
                    id="messagePatternGrid"
                    @cornerSelected="displayArtworks"
                    :classStyle="'sm:w-72 sm:h-72 md:w-100 md:h-100 lg:w-107 lg:h-107 xl:w-112 xl:h-112 transition-all duration-200 ease-out mt-3 sm:mt-0 z-10'"
                />
                <!-- Result image -->
                <div
                   v-show="imagePatternDataUrl"
                    id="patternImageContainer"
                    class="relative md:absolute group sm:w-72 sm:h-72 md:w-100 md:h-100 lg:w-107 lg:h-107 xl:w-112 xl:h-112 transition-all duration-200 ease-out mt-3 sm:mt-0 bg-black"
                >
                    <div
                        class="group-hover:opacity-100 opacity-0 bg-opacity-75 flex absolute justify-center items-center top-0 bottom-0 bg-black w-full h-full transition-all duration-200 ease-out"
                    >
                        <AButton :onClick="downloadImage">Download</AButton>
                    </div>
                </div>
            </div>
        </transition>

        <!-- Pattern Actions  -->
        <!-- <transition name="slide-fade-up-enter" > -->
        <div
            id="patternActions"
            v-show="!showPatternResult"
            class="grid md:col-span-5 xl:col-span-4 md:overflow-y-scroll row-start-1 md:row-start-auto md:flex flex-col md:justify-between md:items-stretch text-black font-black p-5 h-full w-full bg-white"
        >
            <transition name="slide-fade-up" appear>
                <div class="">
                    <!-- Header -->
                    <div class="mb-5 flex items-start justify-between md:mt-5">
                        <h3 class="text-3xl text-left flex flex-col">
                            <div>Malaria, we're too</div>
                            <div>
                                <span class="text-green-500 underline"> {{ word }}</span> for you
                            </div>
                        </h3>
                        <span @click="$emit('close')" class="cursor-pointer text-green-500">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </span>
                    </div>
                    <!-- Incitive -->
                    <p class="text-left leading-4 font-thin tracking-normal">Click randomize or select empty squares to pick icons to complete your artwork.</p>
                    <!-- Commands -->
                    <div class="mt-5 grid grid-cols-5 gap-3 md:gap-5">
                        <div @click="closePatternOptions" class="flex-grow-0">
                            <!-- Selected pattern -->
                            <div v-html="selectedPatternOption.html" class="relative h-10 w-10 p-1 hover:bg-gray-200 cursor-pointer grid grid-cols-2 grid-rows-2 bg-gray-100" />
                        </div>
                        <div class="col-span-4 flex overflow-hidden overflow-x-scroll flex-grow-1 md:w-full">
                            <!-- More patterns -->
                            <div
                                v-show="showPatternOptions"
                                class="grid grid-flow-col md:flex flex-shrink-0 gap-3 xl:gap-5 items-center uppercase pl-3 xl:pl-5 text-green-500 text-xl border-l-2 border-gray-100"
                            >
                                <div
                                    v-for="(option, index) in patternOptions"
                                    :key="index"
                                    @click="selectPatternOption(index)"
                                    v-html="option.html"
                                    class="pattern-option-card relative h-10 w-10 p-1 hover:bg-gray-200 cursor-pointer grid grid-cols-2 grid-rows-2 bg-gray-100"
                                />
                            </div>
                            <div
                                v-show="!showPatternOptions"
                                class="w-full flex justify-between items-center uppercase pl-3 xl:pl-5 text-green-500 text-xl border-l-2 border-gray-100"
                            >
                                <div @click="openPatternOptions" class="cursor-pointer hover:text-green-600">Edit grid</div>
                                <div @click="randomizePatternImages" class="cursor-pointer hover:text-green-600">Randomize</div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- Artworks -->
            <div>
                <!-- Artwork Desktop -->
                <div class="hidden md:block">
                    <transition name="fade-in">
                        <div v-show="canDisplayArtworks" class="">
                            <h4 class="mb-4 text-black uppercase text-left text-lg">Select artwork</h4>
                            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                                <div
                                    @click="setCornerImage(artwork)"
                                    v-for="(artwork, index) in artworks"
                                    :key="index"
                                    class="artwork-card-animated border-4 border-black bg-white transform hover:scale-110 transition-transform duration-200 ease-out cursor-pointer"
                                >
                                    <img :src="artwork" :alt="'Artwork' + index" />
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>

                <!-- Artwork Mobile -->
                <div class="md:hidden">
                    <transition name="slide" mode="out-in">
                        <div v-show="canDisplayArtworksModal" class="fixed flex justify-end top-0 z-10 left-0 bottom-0 w-full h-full bg-black bg-opacity-80">
                            <div class="uppercase font-black p-5 w-5/6 md:w-1/2 lg:w-1/3 xl:w-2/5 h-full bg-white">
                                <div class="mb-5 text-black flex items-center justify-between">
                                    <h3>Select Artwork</h3>
                                    <span @click="closeArtworksModal" class="cursor-pointer text-black">
                                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fill-rule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </span>
                                </div>
                                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 pr-10">
                                    <div @click="setCornerImage(artwork)" v-for="(artwork, index) in artworks" :key="index" class="artwork-card border-4 border-black">
                                        <img :src="artwork" :alt="'Artwork' + index" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>

            <div class="hidden mt-5 md:block">
                <!-- <AButton :onClick="savePattern" :isValid="isPatternValid">Add your line</AButton> -->
                <AButton v-if="!showPatternResult" :onClick="(isPatternValid && !savingPattern) ? savePattern : null" :isValid="isPatternValid">
                    <span v-if="savingPattern">SAVING ...</span>
                    <span v-else>Add your line</span>
                </AButton>
            </div>

            <!--  -->
        </div>
        <!-- </transition> -->

        <!-- Message Summay -->
        <!-- <transition name="slide-fade-up-enter"> -->
        <div
            id="patternSummary"
            v-show="showPatternResult"
            class="relative grid md:col-span-5 xl:col-span-4 md:overflow-y-scroll row-start-1 md:row-start-auto md:flex flex-col md:justify-center md:items-stretch text-white font-black p-5 h-full w-full bg-gray-900 md:border-l-2 border-gray-800"
        >
            <!-- Top Buttons -->
            <div class="flex justify-between items-center mb-5 md:mb-auto">
                <transition name="fade-in" appear>
                    <div
                        @click="gotoPatternPage"
                        class="top-2 md:top-5 left-2 md:left-5 p-2 text-gray-700 hover:text-gray-400 transition-colors duration-200 ease-out cursor-pointer"
                    >
                        <svg class="w-10 h-10 md:w-12 md:h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                        </svg>
                    </div>
                </transition>
                <transition name="fade-in" appear>
                    <div
                        @click="gotoZeroMalaria"
                        class="top-2 md:top-5 right-2 md:right-5 p-2 border-2 border-gray-800 hover:bg-gray-700 transition-colors duration-200 ease-out cursor-pointer"
                    >
                        <img
                            class="w-10 h-10 md:w-12 md:h-12"
                            src="https://zeromalaria.org/themes/custom/malarianomore/images/mosquito-cursor/mosquito-cursor--default.svg"
                            alt=""
                        />
                    </div>
                </transition>
            </div>

            <!-- Message summary content -->
            <!-- <transition name="slide-fade-up" appear> -->
            <div class="md:mb-auto">
                <!-- Header -->
                <h3 class="uppercase text-3xl md:text-4xl text-left md:flex flex-col">
                    <span>Malaria, we're too </span>
                    <span> {{ word }} for you </span>
                </h3>
                <!-- Incitive -->
                <div class="mt-8 text-left leading-4 font-thin tracking-normal">
                    <p>
                        Thank you for adding your message. This website is a discount version of
                        <a href="https://zeromalaria.org/" class="text-green-500 font-bold text-lg">Zeromalaria.org</a> I made because why not.
                        <a href="https://zeromalaria.org/" class="text-green-500 font-medium" title="..and don't harrass me. Except with projects, you can harass me with projects"
                            >Please show them some love</a
                        >.
                    </p>
                </div>
                <!-- Actions -->
                <div class="mt-10 hidden md:block">
                    <AButton :onClick="gotoZeroMalaria" outlined class="">Visit Zeromalaria</AButton>
                    <AButton :onClick="gotoExplore" class="mt-3">Explore grid</AButton>
                </div>
            </div>
            <!-- </transition> -->
        </div>
        <!-- </transition> -->
        <!-- Mobile bottom buttons -->
        <div class="bg-gray-900 p-4 pt-8 md:hidden">
            <!-- Pattern -->
            <AButton v-if="!showPatternResult" :onClick="(isPatternValid && !savingPattern) ? savePattern : null" :isValid="isPatternValid" class="md:hidden">
                <span v-if="savingPattern">SAVING ...</span>
                <span v-else>Add your line</span>
            </AButton>
            <!-- Pattern result -->
            <div v-if="showPatternResult" class="md:hidden">
                <AButton :onClick="gotoZeroMalaria" outlined>Visit Zeromalaria</AButton>
                <AButton :onClick="gotoExplore" class="mt-3">Explore grid</AButton>
            </div>
        </div>
    </div>
</template>

<script>
import MessagePatternGrid from "../MessagePatternGrid.vue";

import gsap from "gsap";
import AButton from "../AButton.vue";

// import html2canvas from 'html2canvas';
import { saveAs } from "file-saver";

import domtoimage from "dom-to-image";

export default {
    name: "MessagePatternPage",
    components: {
        MessagePatternGrid,
        AButton,
    },
    inject: ["ss"],
    data() {
        return {
            showPatternOptions: false,
            message: null,
            imagePatternDataUrl: null,
            showPatternResult: false,
            showArtworkModal: false,
            savingPattern: false,
        };
    },
    mounted() {
        
    },

    computed: {
        artworks() {
            return this.ss.getters.artworks();
        },
        canDisplayArtworks() {
            return this.ss.state.showArtworks || this.ss.state.selectedCorner != null;
        },
        canDisplayArtworksModal() {
            return this.ss.state.showArtworks;
        },

        isPatternValid() {
            return this.ss.getters.isPatternValid();
        },
        patternOptions() {
            return this.ss.state.patternOptions;
        },
        selectedCorner() {
            return this.ss.state.selectedCorner;
        },
        selectedPatternOption() {
            return this.ss.getters.selectedPatternOption();
        },

        word() {
            return this.ss.state.word;
        },
    },
    methods: {
        closeArtworksModal() {
            const tween = gsap.to(".artwork-card-animated", {
                opacity: 0,
                duration: 0.2,
                ease: "ease",
                stagger: -0.1,
            });
            this.ss.methods.closeArtworksModal();
            tween.pause(0);
        },
        closePatternOptions() {
            this.showPatternOptions = false;
        },
        displayArtworks() {
            this.ss.appLog(">> displayArtworks", this.canDisplayArtworks);
            // this.showArtworks = true;
            gsap.from(".artwork-card-animated", {
                opacity: 0,
                duration: 0.3,
                ease: "ease",
                stagger: 0.1,
            });
        },
        downloadImage() {
            var message = "Malaria, we're too " + this.ss.state.word;
            saveAs(this.imagePatternDataUrl, message + ".jpg");
        },
        async gotoPatternPage() {
            this.savingPattern = false;

            // ? slide fade down summary div
            const patternSummaryTween = await gsap.to("#patternSummary", {
                y: 30,
                opacity: 0,
                duration: 0.3,
                ease: "ease",
            });

            // remove last child which is the generated image pattern
            this.showPatternResult = false;
            const patternImageContainer = document.querySelector("#patternImageContainer");
            patternImageContainer.removeChild(patternImageContainer.firstChild);
            // clear patter image
            this.imagePatternDataUrl = null
            // ? slide fade up actions div
            await gsap.fromTo(
                "#patternActions",
                {
                    y: 30,
                    opacity: 0,
                    duration: 0.3,
                    ease: "ease",
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 0.2,
                    ease: "ease",
                }
            );
            // reset summary div
            patternSummaryTween.pause(0);
        },
        gotoExplore() {
            // Reset forms n stuff or not
            
            this.ss.appLog(">>> message", this.ss.state.message);
            //
            this.$router.push({ name: "Explore", query: { id: this.ss.state.message.id } });
            
        },
        gotoZeroMalaria() {
            window.open("https://zeromalaria.org", "", "_blank");
        },
        imageUrl(index) {
            return `https://api.zeromalaria.org/images/artwork_small/en/${index}.jpg`;
        },
        openPatternOptions() {
            this.showPatternOptions = true;
            // ? stagger animate patter option cards
            gsap.from(".pattern-option-card", {
                opacity: 0,
                x: 1,
                duration: 0.1,
                ease: "ease",
                stagger: 0.05,
            });
        },

        async savePattern() {
            this.ss.appLog(">> pattern");

            this.savingPattern = true;
            // Generate image;
            // messagePatternGrid
            // Reset selected corner
            //

            this.ss.methods.resetSelectedCorner();

            const patternNode = document.querySelector("#messagePatternGrid");
            const dataUrl = await domtoimage.toPng(patternNode);

            var img = new Image();
            img.src = dataUrl;
            img.alt = "Malaria, we're too " + this.ss.state.word + " for you";
            this.imagePatternDataUrl = dataUrl;
            // To display image
            const patternImageContainer = document.querySelector("#patternImageContainer");
            this.canvasImageReady = true;
            if(patternImageContainer.children.length < 2){
                patternImageContainer.prepend(img);
            }

            // Save message with image
            await this.ss.methods.saveMessage(img.src);

            // var c = false
            // if (c) {
                //
                this.ss.appLog(">>saved message", this.ss.state.message);
                this.savingPattern = false;
                // ? slide fade down actions div
                const patterActionsTween = await gsap.to("#patternActions", {
                    y: 50,
                    opacity: 0,
                    duration: 0.3,
                    ease: "ease",
                });

                this.showPatternResult = true;
                patterActionsTween.pause(0);

                // ? slide fade up summary div

                await gsap.fromTo(
                    "#patternSummary",
                    {
                        y: 50,
                        opacity: 0,
                        duration: 0.2,
                        ease: "ease",
                    },
                    {
                        y: 0,
                        opacity: 1,
                        duration: 0.2,
                        ease: "ease",
                    }
                );
            // }
        },
        randomizePatternImages() {
            this.ss.methods.randomizePatternImages();
        },
        selectPatternOption(index) {
            this.ss.methods.selectPatternOption(index);
            var tween = gsap.to(".pattern-option-card", {
                opacity: 0,
                x: 1,
                duration: 0.1,
                ease: "ease",
                stagger: -0.05,
                onComplete: () => {
                    this.showPatternOptions = false;
                    tween.pause(0);
                },
            });
        },

        setCornerImage(image) {
            this.ss.methods.setSelectedCornerImage(image);
        },
    },
};
</script>

<style scoped>
.slide-fade-up-enter-enter-from {
    opacity: 0;
    transform: translateY(20px);
}
.slide-fade-up-enter-enter-active {
    transition: transform 0.3s ease-out, opacity 0.4s ease-in-out;
}
</style>
