import { reactive, readonly } from "vue";
import axios from "axios";

import data from "./data";

// localStorage.messages = JSON.stringify([])
const app = {
    // dev: process.env.VUE_APP_DEBUG,
    // dev: true,
    dev: false,
    apiKey: "AmaneHosanna",
};

const state = reactive({
    artworks: data.artworks,
    artworksLarge: data.artworksLarge,

    country: null,
    deactivateCanvasClick: false,
    // fillerIndex: 1,
    fillers: data.fillers,
    message: null,
    name: null,
    notification: null,

    patternCorners: {
        tr: null,
        br: null,
        bl: null,
        tl: null,
    },

    patternOptions: data.patternOptions,
    //
    //
    selectedCorner: null,
    // selectedPatternOption: null,
    selectedPatternOptionIndex: 0,

    showWordOptions: false,
    showCountryInput: false,

    showPatternPage: false,
    showArtworks: false,

    word: null,
});

const appLog = function (...args) {
    if (app.dev) {
        console.log(...args);
    }
};

appLog(">> store", data);
appLog(">>> localStorage", localStorage);

var messages = reactive([]);

// fetch api messages
// if (localStorage.messages) {
//     try {
//         appLog(">> ''", localStorage.messages);
//         messages = JSON.parse(localStorage.messages);
//         appLog(">> []", messages);
//     } catch (e) {
//         messages = [];
//         appLog(">> error fetching JSON messages");
//     }
// }

appLog(">>> messages", messages);
const getters = readonly({
    artworks() {
        // return large artwork for big tiles
        if (state.selectedCorner && state.selectedCorner == state.selectedCorner.toUpperCase()) {
            return state.artworksLarge;
        }
        // return small ones
        return state.artworks;
    },
    fillerImages() {
        return state.fillers;
    },
    isPatternValid() {
        for (const corner in state.patternCorners) {
            if (!state.patternCorners[corner]) {
                return false;
            }
        }
        return true;
    },
    selectedPatternOption() {
        return state.patternOptions[state.selectedPatternOptionIndex];
    },
});

const methods = {
    closePatternPage() {
        state.showPatternPage = false;
    },
    closeArtworksModal() {
        state.showArtworks = false;
    },
    closeWordOptions() {
        state.showWordOptions = false;
    },
    deactivateCanvasClick(val) {
        state.deactivateCanvasClick = val;
    },
    // TODO : make an appInit function
    async fetchMessages() {
        appLog(">> fetchMessages");
        try {
            // Fetch API messages
            // const response = await axios.get("http://localhost:8000/portfolio/projects/zeromalaria/data?apikey=" + app.apiKey);
            const url = `${process.env.VUE_APP_API_URL}projects/zeromalaria/messages?apikey=${process.env.VUE_APP_API_KEY}`;
            appLog(">> r", url);
            const response = await axios.get(url);
            appLog("<< r", url, response.status, response);

            if (response.status !== 200) {
                // app.error.apiError = true;
                //
                if (response.status >= 300) {
                    state.notification = "Too many redirects";
                }
                if (response.status >= 400) {
                    state.notification = "Something happened";
                }
                if (response.status >= 500) {
                    state.notification = "Could not connect to amane.dev";
                    // app.error.image =
                    // "https://img.icons8.com/external-flatart-icons-flat-flatarticons/64/000000/external-error-user-interface-flatart-icons-flat-flatarticons-1.png";
                }
                //
                return [];
            }

            // Process response data
            const reponseData = await response.data;
            appLog("<< d", reponseData);

            if (reponseData && reponseData.error) {
                // state.notification = reponseData.message;
                appLog("<< de", reponseData);
                return [];
            }
            if (reponseData && reponseData.success) {
                // state.notification = "Thank You";
                messages.push(...reponseData.data);
                messages = reponseData.data;

                appLog("<< datamessages", reponseData.data);

                // messages = messages.map((msg) => {
                //     return { ...msg, image: process.env.VUE_APP_IMG_URL + msg.image };
                // });
                appLog("<< messages", messages);

                return messages;
            }
        } catch (er) {
            appLog("xx fetchMessages er", er);

            state.notification = "Something happened with the server";
            return [];
        }
        appLog("<< fetchMessages");
    },

    openWordOptions() {
        state.showWordOptions = true;
    },
    openPatternPage() {
        state.showPatternPage = true;
    },
    randomizeCountry() {
        state.country = data.countries[Math.floor(Math.random() * data.countries.length)];
        appLog(">> randCont", state.country);
    },
    randomizePatternImages() {
        // ? Set up a random image for each pattern corner
        for (const key in state.patternCorners) {
            state.selectedCorner = key;
            const randomImage = getters.artworks()[Math.floor(Math.random() * getters.artworks().length)];
            appLog(">> random", randomImage);
            state.patternCorners[key] = randomImage;
        }
        state.selectedCorner = null;
    },
    randomizeSelectedPatternOption() {
        methods.selectPatternOption(Math.floor(Math.random() * state.patternOptions.length));
    },
    randomizeWord() {
        state.word = data.words[Math.floor(Math.random() * data.words.length)];
    },
    resetAllInput() {
        methods.resetCountry();
        methods.resetName();
        methods.resetSelectedCorner();
        methods.resetWord();
    },
    resetCountry() {
        state.country = null;
    },
    resetName() {
        state.name = null;
    },

    resetSelectedCorner() {
        state.selectedCorner = null;
        // close artwork modal
        state.showArtworks = false;
    },
    resetWord() {
        state.word = null;
    },

    async saveMessage(imageData) {
        if (!messages) {
            messages = [];
        }
        const message = {
            index: messages.length + 1,
            country: state.country,
            created_at: new Date().toISOString(),
            image: imageData,
            name: state.name,
            word: state.word,
        };

        state.message = message;
        messages.push(message);

        // API save message
        try {
            // Setup input
            const url = process.env.VUE_APP_API_URL + "projects/zeromalaria/messages?apikey=" + process.env.VUE_APP_API_KEY;

            const response = await axios.post(url, message);

            appLog("<< r", url, response.status, response);

            if (response.status !== 200) {
                // app.error.apiError = true;
                //
                if (response.status >= 300) {
                    state.notification = "Too many redirects";
                }
                if (response.status >= 400) {
                    state.notification = "Something happened";
                }
                if (response.status >= 500) {
                    state.notification = "Could not connect to amane.dev";
                    // app.error.image =
                    // "https://img.icons8.com/external-flatart-icons-flat-flatarticons/64/000000/external-error-user-interface-flatart-icons-flat-flatarticons-1.png";
                }
                //
                return;
            }

            // Process response data
            const data = await response.data;
            appLog("<< d", data);

            messages.push(data);

            if (data && data.error) {
                state.notification = data.message;
                appLog("<< de", data);
                return;
            }
            if (data && data.success) {
                state.notification = "Thank You";
                appLog("<< ds", data);
                return;
            }
        } catch (er) {
            appLog("xx fetch er", er);

            state.notification = "Something happened with the server";
        }

        // try {
        //     // Save messages to localstorage
        //     localStorage.messages = [];
        //     localStorage.messages = JSON.stringify(messages);
        // } catch (storageErr) {
        //     appLog("xx storageError ", storageErr);
        // }
    },
    saveWord() {
        state.store.word = state.word;
        state.showPatternPage = true;
    },
    selectCorner(corner) {
        state.selectedCorner = corner;
        // Open artwork modal
        state.showArtworks = true;
    },
    selectPatternOption(index) {
        state.selectedPatternOptionIndex = index;
        state.selectedPatternOption = state.patternOptions[index];
        // ? Reset selectedCorner
        state.selectedCorner = null;
        // ? Reset and setup patternCorners for selected pattern option
        state.patternCorners = {};
        for (var corner of state.patternOptions[index].corners) {
            state.patternCorners[corner] = null;
        }
        // ! will be used later to verify if all corners are filled
    },
    selectWord(word) {
        state.word = word;
        methods.closeWordOptions();
    },

    setCountry(country) {
        state.country = country;
    },
    setName(name) {
        state.name = name;
    },
    setSelectedCornerImage(image) {
        // Set corner image and close showArtworks modal
        state.patternCorners[state.selectedCorner] = image;
        // state.selectedCorner = null
        state.showArtworks = false;
    },
};

export default {
    app: readonly(app),
    appLog,
    state,
    getters,
    messages,
    methods,
};
