<template>
    <div id="zoomParent" class="h-full w-full bg-white overflow-hidden border border-gray-900">
        <div id="zoomContainer" class="zoomable relative h-full w-full grid grid-cols-5 grid-rows-5 bg-gray-800 gap-2">
            <div @click="zoom" class="cursor-pointer bg-blue-500" style="background-image: url(assets/star_flight.gif); background-size: contain;"></div>
            <div @click="zoom" class="cursor-pointer col-span-2 bg-red-500 bg-image" style="background-image: url(assets/audit.png)"></div>
            <div @click="zoom" class="cursor-pointer bg-gray-500 bg-image" style="background-image: url(assets/maigo.png); background-position: bottom;"></div>

            <div @click="zoom" class="cursor-pointer row-span-2 bg-yellow-500 bg-image" style="background-image: url(assets/opinion24.png)"></div>
            <div @click="zoom" class="cursor-pointer row-span-2 bg-blue-400 bg-image" style="background-image: url(assets/calc_options.png);"></div>
            <!-- <div @click="zoom" class="cursor-pointer bg-red-400"></div> -->
            <div @click.stop="zoom" :class="['relative cursor-pointer overflow-hidden bg-image bg-red-400', zoomIn ? 'zoomIn' : '']" style="background-image: url(assets/cabine_admin.png)"></div>
            <div
                id="p3"
                @click.stop="zoom($event, '/zeromalaria/explore')"
                :class="['relative  overflow-hidden bg-image cursor-pointer col-span-2 row-span-2 bg-gray-400', zoomIn ? 'zoomIn' : '']" style="background-image: url(assets/zeromalaria.jpg)"
            >
                <!-- <img @click="null" src="@/assets/zeromalaria.jpg" alt="" class="cursor-pointer -z-5" /> -->
                <!-- <iframe @click="e.preventDefault()" src="http://localhost:8080/zeromalaria/explore" class="cursor-pointer h-full w-full" frameborder="0"></iframe> -->
            </div>
            <div @click="zoom" class="cursor-pointer bg-yellow-400 bg-image" style="background-image: url(assets/chic_dame.png)"></div>
            <div @click="zoom" class="cursor-pointer bg-blue-300 bg-image" style="background-image: url(assets/a_star.gif); background-size: contain"></div>
            <div @click="zoom" class="cursor-pointer col-span-2 row-span-2 bg-red-300 bg-image" style="background-image: url(assets/cocovico.png)"></div>
            <div @click="zoom" class="cursor-pointer row-span-2 bg-gray-300 bg-image" style="background-image: url(assets/dix.webp)"></div>
            <div @click="zoom" class="cursor-pointer col-span-2 bg-yellow-300 bg-image" style="background-image: url(assets/visvas.png)"></div>

            <div @click="zoom" class="cursor-pointer bg-blue-600 bg-image" style="background-image: url(assets/dnews.jpeg); background-position: bottom"></div>
            <div @click="zoom" class="cursor-pointer bg-red-600 bg-image" style="background-image: url(assets/purple_rain.gif)"></div>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
var tl = gsap.timeline();
export default {
    name: "Welcome",
    data() {
        return {
            zoomIn: false,
            zoomElement: null,

            projects: [
                {
                    id: 1,
                    name: "ZeroMalaria",
                    logo: "",
                    image: "",
                    poster: "",
                    images: [],
                    title: "",
                    description: "",
                    features: [],
                    technologies: [],
                    createdAt: "",
                    deliveredAt: "",
                    url: "",
                },
            ],
        };
    },
    mounted() {
        //
        // tl.to(".zoomable", {
        //     scaleY: 2.99,
        //     scaleX: 2.77,
        //     translateX: "-25%",
        //     translateY: "12.5%",
        //     duration: 0.1,
        // });
    },
    computed: {
        scaleX() {
            var rect = this.zoomElement.getBoundingClientRect();
            return window.innerWidth / rect.width;
        },
        scaleY() {
            var rect = this.zoomElement.getBoundingClientRect();
            return window.innerHeight / rect.height;
        },
        translateX() {
            var rect = this.zoomElement.getBoundingClientRect();
            return (-rect.left / rect.left) * rect.left + "px";
        },
        translateY() {
            var rect = this.zoomElement.getBoundingClientRect();
            return window.innerHeight - rect.bottom + "px";
        },
    },
    methods: {
        zoom($event, url = null) {
            this.zoomIn = !this.zoomIn;
            var element = (element = $event.target);
            var rect = element.getBoundingClientRect();
            var scaleX = 2;
            var scaleY = 2;
            // get scale ratio for panel element f(viewPort)
            scaleX = window.innerWidth / rect.width;
            scaleY = window.innerHeight / rect.height;

            if (this.zoomIn) {
                tl.to(".zoomable", {
                    scaleX,
                    scaleY,
                    ease: "ease.in",
                    duration: 0.3,
                    onComplete: async () => {
                        // get freshly scaled panel element's bbox
                        rect = element.getBoundingClientRect();
                        tl.to(".zoomable", {
                            translateX: (-rect.left / rect.left) * rect.left + "px",
                            translateY: window.innerHeight - rect.bottom + "px",
                            ease: "ease.out",
                            duration: 0.5,
                        });
                        if (url)
                            setTimeout(() => {
                                this.$router.push(url);
                            }, 1000);
                    },
                });
            } else {
                tl.to(".zoomable", {
                    scaleY: 1,
                    scaleX: 1,
                    translateX: "0%",
                    translateY: "0%",
                    duration: 0.7,
                });
            }
        },
    },

    // // scaleX: this.scaleX,
    //                 // scaleY: this.scaleY,
    //                 // translateX: this.translateX,
    //                 // translateY: this.translateY,
    //                 // translateX: (-rect.left/rect.left)*rect.left +'px',
    //                 // translateY: window.innerHeight - rect.bottom  +'px',
    //                 scaleX: scaleX /2,
    //                 scaleY: scaleY /2,
    //                 // translateX: (-element.getBoundingClientRect().left/element.getBoundingClientRect().left)*element.getBoundingClientRect().left + 'px',
    //                 // translateY: translateY,
    //                 duration: .3,
    //                 onComplete:async ()=>{
    //                     console.log(">>>>", scaleX, rect.left);
    //                     var previousLeft = rect.left
    //                     rect = element.getBoundingClientRect();
    //                     // console.log("<<<<",previousLeft, rect.left, Math.max(previousLeft, rect.left)/Math.min(previousLeft, rect.left));
    //                     console.log("<<<<==", Math.max(previousLeft, rect.left)/Math.min(previousLeft, rect.left));
    //                     await tl.to(".zoomable", {
    //                         translateX: (-rect.left/rect.left)*rect.left +'px',
    //                         translateY: window.innerHeight - rect.bottom  +'px',
    //                         scaleX: scaleX /2,
    //                         scaleY: scaleY /2,
    //                     //     duration: .4

    //                     })
    //                 }
};
</script>

<style>
html,
body {
    height: 100vh;
}

/* Animations */

.zoomable {
    /* transition: all 0.7s ease-out;
    transform-origin: center; */
}

.zoomable.zoomIn {
    /* transform: scale(2); */
    /* height: 100vh;
    width: 100vw; */
    /* transform-origin: center; */
    /* transform: scaleX(2.7) scaleY(2.9) translate(-25%, 12.5%);
    animation: zoomIn 0.7s ease-out 1; */
}

.bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background-size: contain; */
}
</style>
