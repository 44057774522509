<template>
    <div  class="bg-gray-100 md:bg-gray-50 h-full">
        <main class="mx-auto py-3 sm:py-5 md:mp-10 w-full md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:8/12">
            <router-link :to="{name: 'Amortissement'}" class="flex border-b sm:border-0 justify-center py-5">
                <img width="100" src="/images/logo_zoe.png" alt="Logo Zoe BP" />
            </router-link>

            <div class="sm:border sm:rounded-md w-full bg-white sm:shadow">
                <div class="mb-5 border-b p-5 sm:p-5 md:py-10">
                    <h1 class="uppercase font-semibold text-center">Tableau d'amortissement financier</h1>
                    <!-- // ? Incitive -->
                    <p class=" mt-2 md:mt-3 sm:block text-gray-500 px-3 sm:px-5 md:2/3 lg:w-1/2 text-center mx-auto">Remplissez le formulaire ci-dessous pour calculer votre amortissement</p>
                </div>
                <div class="pb-10">
                <!-- // ? Input Headers -->
                    <div class="p-2 sm:p-5 pb-5 mb-5 border-b grid sm:grid-cols-2 md:grid-cols-4 gap-3 md:gap-5">
                        <!-- // ? Emprunt  -->
                        <label for="amount" class="flex md:block justify-between items-center gap-2">
                            <div class="text-left whitespace-nowrap">Emprunt :</div>
                            <div class="flex w-full sm:w-auto items-center rounded border bg-gray-50 px-2">
                                <input
                                    class="w-full px-1 py-0.5 text-right bg-transparent text-blue-500 font-medium focus:outline-none"
                                    id="amount"
                                    v-model="capital"
                                    autocomplete="off"
                                    spellcheck="off"
                                    type="number"
                                    min="0"
                                    step="25"
                                />
                                FCFA
                            </div>
                        </label>
                        <!-- // ? TauxAnnuel -->
                        <label for="taux" class="flex md:block justify-between items-center gap-2">
                            <div class="text-left whitespace-nowrap">Taux Annuel :</div>
                            <div class="flex w-full sm:w-auto items-center rounded border bg-gray-50 px-2">
                                <input
                                    class="w-full px-1 py-0.5 text-right bg-transparent text-blue-500 font-medium focus:outline-none"
                                    id="taux"
                                    v-model="taux_annuel"
                                    autocomplete="off"
                                    spellcheck="off"
                                    type="number"
                                    min="0"
                                />
                                %
                            </div>
                        </label>
                        <!-- // ? TOB -->
                        <label for="tob" class="flex md:block justify-between items-center gap-2">
                            <div class="text-left whitespace-nowrap">TOB :</div>
                            <div class="flex w-full sm:w-auto items-center rounded border bg-gray-50 px-2">
                                <input
                                    class="w-full px-1 py-0.5 text-right bg-transparent text-blue-500 font-medium focus:outline-none"
                                    id="tob"
                                    v-model="tob"
                                    autocomplete="off"
                                    spellcheck="off"
                                    type="number"
                                    min="0"
                                />
                                %
                            </div>
                        </label>
                        <!-- // ? DUREE -->
                        <label for="duree" class="flex md:block justify-between items-center gap-2">
                            <div class="text-left whitespace-nowrap">Durée :</div>
                            <div class="flex w-full sm:w-auto items-center rounded border bg-gray-50 px-2">
                                <input
                                    class="w-full px-1 py-0.5 text-right bg-transparent text-blue-500 font-medium focus:outline-none"
                                    id="duree"
                                    v-model="duree"
                                    autocomplete="off"
                                    spellcheck="off"
                                    type="number"
                                    min="0"
                                />
                                AN{{ duree > 1 ? "S" : "" }}
                            </div>
                        </label>
                    </div>
                    <!-- // ? Table -->
                    <div class="overflow-x-scroll px-2 sm:px-5  flex justify-start sm:justify-center md:justify-self-auto font-medium">
                        <table class="w-full">
                            <!-- // ? Table Headers -->
                            <thead>
                                <tr class="">
                                    <th class="border bg-gray-50 justify-center items-center p-2 md:px-3  whitespace-nowrap">Année</th>
                                    <th class="border bg-gray-50 justify-center items-center p-2 md:px-3  whitespace-nowrap">Restant du capital</th>
                                    <th class="border bg-gray-50 justify-center items-center p-2 md:px-3  whitespace-nowrap">Intérêt</th>
                                    <th class="border bg-gray-50 justify-center items-center p-2 md:px-3  whitespace-nowrap">TOB</th>
                                    <th class="border bg-gray-50 justify-center items-center p-2 md:px-3  whitespace-nowrap">Amortissement</th>
                                    <th class="border bg-gray-50 justify-center items-center p-2 md:px-3  whitespace-nowrap">Annuité</th>
                                </tr>
                            </thead>
                            <!-- // ? Table Body -->

                            <tbody v-if="ammortissements.length > 0">
                                <tr v-for="ammort in ammortissements" :key="ammort.key" :class="[ammort.key %2 == 0 ? 'bg-gray-100': '']">
                                    <td class="border px-2">{{ ammort.annee }}</td>
                                    <td class="border px-2 text-right">{{ formatter.format(ammort.capital) ?? "-" }}</td>
                                    <td class="border px-2 text-right">{{ formatter.format(ammort.interet) }}</td>
                                    <td class="border px-2 text-right">{{ formatter.format(ammort.tob) }}</td>
                                    <td class="border px-2 text-right">{{ formatter.format(ammort.amortissement) }}</td>
                                    <td class="border px-2 text-right">{{ formatter.format(ammort.annuite) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="6" class="border p-2 ">--Wow, Such Empty--</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="pt-10 md:pt-20 font-medium bg-gray-100 md:bg-gray-50">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=2250574936826&text=Wow%20nice%20accounting%20app%20Amane">
                あ
                </a>
            </div>
        </main>
    </div>
</template>

<script>
// import gsap from "gsap";
// var tl = gsap.timeline();
export default {
    name: "AmortissementFinancier",
    data() {
        return {
            capital: 10000000,
            taux_annuel: 10,
            tob: 10,
            duree: 3,
            formatter: new Intl.NumberFormat("fr-FR"),
        };
    },
    mounted() {},
    computed: {
        ammortissements() {
            let data = [];
            for (var i = 1; i <= this.duree; i++) {
                // ? For year 1
                var instance = {};
                instance.key = i;
                instance.capital = this.capital;
                instance.interet = instance.capital * (this.taux_annuel / 100);
                instance.tob = instance.capital * (this.tob / 100);
                if (instance.capital >= 1) {
                    // instance.annuite = this.capital * (this.taux_annuel /100) / ((1-(1+(this.taux_annuel/100))^(-this.duree)))
                    instance.annuite = (instance.capital * (this.taux_annuel / 100)) / (1 - Math.pow(1 + this.taux_annuel / 100, -this.duree));
                }
                instance.amortissement = instance.annuite - instance.interet;

                // ? For years after year 1
                if (i > 1) {
                    var prev_instance = data[i - 2];

                    // instance.interet = '-'
                    // instance.capital = '-'
                    // instance.tob = '-'
                    // instance.amortissement = '-'
                    // instance.annuite = '-'
                    //
                    //
                    if (prev_instance) {
                        instance.capital = prev_instance.capital - prev_instance.amortissement;
                        instance.interet = instance.capital * (this.taux_annuel / 100);
                        instance.tob = instance.capital * (this.tob / 100);
                        // ! Don't recalculate annuite
                        instance.amortissement = instance.annuite - instance.interet;
                    }
                }
                //

                instance.interet = Math.round(instance.interet);
                instance.capital = Math.round(instance.capital);
                instance.tob = Math.round(instance.tob);
                instance.amortissement = Math.round(instance.amortissement);
                instance.annuite = Math.round(instance.annuite);

                instance.annee = "AN" + i;

                if (instance.capital > 0) {
                    data.push(instance);
                }
                // prev_instance = instance
            }

            return data;
        },
    },
    methods: {},
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
html,
body {
    height: 100vh;
    margin: 0;
    padding: 0;
}

/* Animations */

.zoomable {
    /* transition: all 0.7s ease-out;
    transform-origin: center; */
}

.zoomable.zoomIn {
    /* transform: scale(2); */
    /* height: 100vh;
    width: 100vw; */
    /* transform-origin: center; */
    /* transform: scaleX(2.7) scaleY(2.9) translate(-25%, 12.5%);
    animation: zoomIn 0.7s ease-out 1; */
}

.bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background-size: contain; */
}
</style>
