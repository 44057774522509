<template>
    <router-view class="tracking-tightestest select-none"/>
    
    <Notification />
</template>


<script>
import store from '@/store/zeromalaria/index.js'
import data from '@/store/zeromalaria/data.js'
import Notification from '../../components/zeromalaria_components/Notification.vue'

export default {
  components: { Notification },
    name: 'ZeroMalariaLayout',
    provide: {'ss': store, 'data': data},
}
</script>

<style lang="scss">

html, body, #app {
  height: 100vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

// / Transitions
.slide-enter-from,
.slide-leave-to {
    opacity: 0;
    transform: translateX(100%);
}
.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-out;
}

.slide-fade-up-enter-from,
.slide-fade-up-leave-to {
    opacity: 0;
    transform: translateY(40px);
}

.slide-fade-up-enter-active,
.slide-fade-up-leave-active {
    transition: opacity 0.9s ease-out, transform 1s ease-out;
}
.slide-fade-up-delay-enter-from,
.slide-fade-up-delay-leave-to {
    opacity: 0;
    transform: translateY(40px);
}

.slide-fade-up-delay-enter-active,
.slide-fade-up-delay-leave-active {
    transition: opacity 0.9s ease-out 0.2s, transform 1s ease-out 0.2s;
}

.slide-up-enter-from,
.slide-up-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.2s ease-out;
}

.fade-in-enter-from,
.fade-in-leave-to {
    opacity: 0;
}
.fade-in-enter-active {
    transition: opacity 0.3s ease-out;
}
.fade-in-leave-active {
    transition: opacity 0.2s ease-out;
}


.fade-in-delay-enter-from,
.fade-in-delay-leave-to {
    opacity: 0;
}
.fade-in-delay-enter-active {
    transition: opacity 0.3s ease-out .2s;
}
.fade-in-delay-leave-active {
    transition: opacity 0.2s ease-out;
}

.slide-right-enter-from {
    transform: translateX(300px);
}
.slide-right-leave-to {
    opacity: 0;
    transform: translateX(300px);
}
.slide-right-enter-active {
    transition: all 0.4s ease-out .2s;
}
.slide-right-leave-active {
    transition: all 0.3s ease-in;
}
</style>
