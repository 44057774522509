<template>
    <!-- <div  class="my-2 md:my-10 mx-auto w-full md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12 border border-gray-200 rounded">
        <div class="p-5 lg:p-10 border-b border-gray-200">
            <h2 class="text-xl font-semibold">Tableau d'amortissement financier</h2>
        </div>
        <div class="p-3 lg:p-5 rounded w-full">
            <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-5">
                <div class="bg-red-200">
                    <label for="">Emprunt</label>
                    <input type="text" class="p-2 rounded">
                </div>
                <div class="bg-yellow-200">
                    b
                </div>
                <div class="bg-blue-200">
                    c
                </div>
                <div class="bg-red-200">
                    d
                </div>
            </div>
        </div>
    </div> -->
    <main>
        <router-link :to="{name: 'AmortissementFinancier'}" class="mb-5">
            <img width="100" src="/images/logo_zoe.png" alt="Logo Zoe BP">
        </router-link>
        <div class="flex">
            <div class="flex flex-col items-center">
                <h1 class="mb-10 uppercase font-semibold text-left">Tableau d'amortissement financier</h1>
        
                <table class="border border-gray-900">
                    <!-- // ? Input Headers -->
                    <!-- // ? Emprunt  -->
                    <tr class="border border-gray-900">
                        <td class="border border-gray-900 px-2 text-left">Emprunt</td>
                            <td class="border border-gray-900 text-right text-blue-500 font-medium">
                            <label for="amount">
                                    <input id="amount" v-model="capital" autocomplete="off" spellcheck="off" type="number" min="0" step="50" class="w-full text-right focus:outline-none">
                                    <!-- @keyup="capital = formatter.format(capital)" -->
                            </label>
                        </td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2 text-left">Montant (FCFA)</td>
                        <td class="border border-gray-900 px-2"></td>
                    </tr>
                    <!-- // ? TauxAnnuel -->
                    <tr class="border border-gray-900">
                        <td class="border border-gray-900 px-2 text-left">TauxAnnuel</td>
                        <td class="text-blue-500 font-medium flex justify-center">
                            <label for="taux" class="block ">
                                <input id="taux" v-model="taux_annuel" autocomplete="off" spellcheck="off"  type="number" class="w-12  text-right ring-0 focus:outline-none">
                                %
                            </label>
                        </td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                    </tr>
                    <!-- // ? TOB -->
                    <tr class="border border-gray-900">
                        <td class="border border-gray-900 px-2 text-left">TOB</td>
                        <td class="border  text-blue-500 font-medium flex justify-center">
                            <label for="tob" class="block ">
                                <input id="tob" v-model="tob" autocomplete="off" spellcheck="off"  type="number" min="0" max="100" class="w-12 text-right ring-0 focus:outline-none">
                                %
                            </label>
                        </td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                    </tr>
                    <!-- // ? DUREE -->
                    <tr class="border border-gray-900">
                        <td class="border border-gray-900 px-2 text-left">DUREE</td>
                        <td class="border  text-blue-500 font-medium flex justify-center">
                            <label for="annee" class="block">
                                <input id="annee" v-model="duree" autocomplete="off" spellcheck="off"  type="number" min="0" class="w-12 text-right ring-0 focus:outline-none">
                            </label>
                        </td>
                        <td class="border border-gray-900 px-2">ANS</td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                        <td class="border border-gray-900 px-2"></td>
                    </tr>
                    <!-- // ? Table Headers -->
                    <tr class="border border-gray-900">
                        <th class="border border-gray-900 px-2">Année</th>
                        <th class="border border-gray-900 px-2">CAPITAL RESTANT DU</th>
                        <th class="border border-gray-900 px-2">INTERET</th>
                        <th class="border border-gray-900 px-2">TOB</th>
                        <th class="border border-gray-900 px-2">Amortissement</th>
                        <th class="border border-gray-900 px-2">Annuité</th>
                    </tr>
                    <!-- // ? Table Body -->
                    <tbody>
                        <tr v-for="ammort in ammortissements" :key="ammort.annee">
                            <td class="border border-gray-900 px-2">{{ammort.annee}}</td>
                            <td class="border border-gray-900 px-2 text-right">{{formatter.format(ammort.capital) ?? '-'}}</td>
                            <td class="border border-gray-900 px-2 text-right">{{formatter.format(ammort.interet)}}</td>
                            <td class="border border-gray-900 px-2 text-right">{{formatter.format(ammort.tob)}}</td>
                            <td class="border border-gray-900 px-2 text-right">{{formatter.format(ammort.amortissement)}}</td>
                            <td class="border border-gray-900 px-2 text-right">{{formatter.format(ammort.annuite)}}</td>
                        </tr>
                        <!-- <tr>
                            <td class="border border-gray-900 px-2">AN1</td>
                            <td class="border border-gray-900 px-2 text-right">10.000.000</td>
                            <td class="border border-gray-900 px-2 text-right">10.000.000</td>
                            <td class="border border-gray-900 px-2 text-right">10.000.000</td>
                            <td class="border border-gray-900 px-2 text-right">3.021.148</td>
                            <td class="border border-gray-900 px-2 text-right">4.021.148</td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </main>
</template>

<script>
// import gsap from "gsap";
// var tl = gsap.timeline();
export default {
    name: "Amortissement",
    data() {
        return {
            capital: 10000000,
            taux_annuel: 10,
            tob: 10,
            duree: 3,
            formatter: new Intl.NumberFormat('fr-FR'),
            
        };
    },
    mounted() {
        
    },
    computed: {
        ammortissements(){
            let data = [];
            for(var i = 1; i <= this.duree; i++){

                // ? For year 1
                var instance = {}
                instance.capital = this.capital;
                instance.interet = instance.capital * (this.taux_annuel /100);
                instance.tob = instance.capital * (this.tob /100);
                if(instance.capital >= 1){
                    // instance.annuite = this.capital * (this.taux_annuel /100) / ((1-(1+(this.taux_annuel/100))^(-this.duree)))
                    instance.annuite = instance.capital * (this.taux_annuel /100) / (1- Math.pow((1+(this.taux_annuel/100)), -this.duree))
                }
                instance.amortissement = instance.annuite - instance.interet
                

                // ? For years after year 1
                if(i > 1){
                    var prev_instance = data[i-2]

                    // instance.interet = '-'
                    // instance.capital = '-'
                    // instance.tob = '-'
                    // instance.amortissement = '-'
                    // instance.annuite = '-'
                    // 
                    // 
                    if(prev_instance){
                        instance.capital = prev_instance.capital - prev_instance.amortissement;
                        instance.interet = instance.capital * (this.taux_annuel /100);
                        instance.tob = instance.capital * (this.tob /100);
                        // ! Don't recalculate annuite
                        instance.amortissement = instance.annuite - instance.interet
                    }
                }
                // 

                instance.interet = Math.round(instance.interet)
                instance.capital = Math.round(instance.capital)
                instance.tob = Math.round(instance.tob)
                instance.amortissement = Math.round(instance.amortissement)
                instance.annuite = Math.round(instance.annuite)

                instance.annee = 'AN'+i

                if(instance.capital > 0){
                    data.push(instance)
                }
                // prev_instance = instance
            }

            return data
        }
    },
    methods: {
        
    },

};
</script>

<style>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
html,
body {
    height: 100vh;
}

/* Animations */

.zoomable {
    /* transition: all 0.7s ease-out;
    transform-origin: center; */
}

.zoomable.zoomIn {
    /* transform: scale(2); */
    /* height: 100vh;
    width: 100vw; */
    /* transform-origin: center; */
    /* transform: scaleX(2.7) scaleY(2.9) translate(-25%, 12.5%);
    animation: zoomIn 0.7s ease-out 1; */
}

.bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background-size: contain; */
}
</style>
