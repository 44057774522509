<template>
    <transition name="slide-right">
        <div v-show="notification" class="fixed top-5 right-5 p-5 bg-green-500 flex items-center justify-between font-bold text-xl">
            {{notification}}
            <span @click="close" class="pl-5 cursor-pointer">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </span>
        </div>
    </transition>
</template>


<script>
export default {
    name: 'Notification',
    inject: ['ss'],
    computed:{
        notification(){
            return this.ss.state.notification;
        },
    },
    watch: {
        'ss.state.notification': function(){
            setTimeout(() => {
                this.close()
            }, 5000);
        }
    },
    methods: {
        close(){
            this.ss.state.notification = null
        }
    }
}
</script>