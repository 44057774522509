<template>
    <div class="bg-gray-800 h-full md:grid grid-cols-12">
        <div class="hidden md:col-span-7 md:flex flex-col justify-center border-r-2 md:my-5 border-gray-700 p-2">
            <ShufflingPatternBlocks />
        </div>
        <!--   -->
        <div class="col-span-12 md:col-span-5 text-left font-black p-3 md:p-5 flex flex-col h-full justify-between">
            <transition name="slide-fade-up" appear>
                <div>
                    <div class="text-xl text-white">
                        <h1 class="uppercase">Create your own message</h1>
                        <small class="font-normal text-gray-600">Select the word that means the most to you.</small>
                    </div>

                    <div class="mt-10 text-white text-4xl sm:text-5xl md:text-4xl lg:text-5xl font-black">
                        <span class="block mb-2">MALARIA,</span>
                        <span class="block mb-2">WE'RE TOO</span>
                        <div @click="openWordOptions" class="group pb-3 cursor-pointer flex items-center justify-between border-b-2 border-green-500">
                            <div class="whitespace-nowrap" :class="[word ? 'group-hover:text-green-700 text-green-600' : 'group-hover:text-gray-700 text-gray-600']">
                                {{ word != null ? word : "SELECT MESSAGE" }}
                            </div>
                            <span class="text-green-500 group-hover:text-green-600">
                                <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                                </svg>
                            </span>
                        </div>
                        <span class="block my-3">FOR YOU</span>
                    </div>
                </div>
            </transition>

            <div class="relative">
                <!-- Name Input because why not -->
                <transition name="slide-fade-up-delay" appear>
                    <NameInput />
                </transition>
                <transition name="slide-fade-up-delay" appear>
                    <CountryInput />
                </transition>

                <transition name="slide-fade-up-delay" appear>
                    <AButton  @click="isValid ? openPatternPage() : null" :isValid="isValid" >
                        Get started
                    </AButton>
                    
                </transition>
            </div>
        </div>

        <!-- Pattern page data -->
        <transition name="fade-in" mode="out-in" appear>
            <MessagePatternPage v-if="showPatternPage" @close="closePatternPage" @openWordOptions="openWordOptions"/>
        </transition>

        <!-- Overlay words data -->
        <transition name="slide" mode="out-in">
            <WordOptionsModal v-if="showWordOptions" @close="closeWordOptions" />
        </transition>
    </div>
</template>

<script>
import AButton from "@/components/zeromalaria_components/AButton.vue";
import CountryInput from "@/components/zeromalaria_components/CountryInput.vue";
import MessagePatternPage from '@/components/zeromalaria_components/pages/MessagePatternPage.vue'
import NameInput from "@/components/zeromalaria_components/NameInput.vue";
import ShufflingPatternBlocks from "@/components/zeromalaria_components/ShufflingPatternBlocks.vue";
import WordOptionsModal from "@/components/zeromalaria_components/modals/WordOptionsModal.vue";

export default {
    name: "CreateWord",
    components: {
        AButton,
        CountryInput,
        MessagePatternPage,
        NameInput,
        ShufflingPatternBlocks,
        WordOptionsModal,
    },
    inject: ["ss", "data"],
    data() {
        return {};
    },
    mounted() {
        console.log("\n\n<<>>** New Message **<<>>\n\n");
        // this.randomizeCountry()
        // this.randomizeWord()
        // this.randomizeFiller()
        // this.showPatternPage = true
        // this.animateBlocks()
        // this.ss.methods.randomizeWord();
        this.ss.methods.randomizeSelectedPatternOption();
    },
    computed: {

        isValid() {
            return this.word && this.data.countries.find((c) => c == this.ss.state.country) && this.name
        },
        name() {
            return this.ss.state.name;
        },
        showPatternPage() {
            return this.ss.state.showPatternPage
        },
        showWordOptions() {
            return this.ss.state.showWordOptions;
        },
        word() {
            return this.ss.state.word;
        },
        
    },
    methods: {
        closePatternPage() {
            this.ss.methods.closePatternPage();
        },
        closeWordOptions() {
            this.ss.methods.closeWordOptions();
        },
        openPatternPage(){
            this.ss.methods.openPatternPage();
        },
        openWordOptions() {
            this.ss.methods.openWordOptions();
        },
    },
};
</script>