<template>
        <transition name="slide-fade-up-delay" appear>
            <a
                @click="isValid ? onClick() : null"
                class="cursor-pointer block py-5 px-5 text-xl text-center font-black text-black uppercase transition-colors duration-200 ease-out"
                :class="styleClass"
                >
                <slot></slot>
            </a>
        </transition>
</template>

<script>
export default {
    name: 'AButton',
    
    props: {
        isValid: {
            types: [Boolean, String],
            default: true,
        },
        onClick: {
            type: Function,
            default: ()=>{},
        },
        outlined: {
            type: Boolean,
            default: false,
        }
        
    },
    computed:{
        styleClass(){
            var style = ''
            var color = 'gray'
            
            // 
            if(this.isValid){
                color = 'green'
            }

            if(this.outlined){
                style = ' border-4 border-'+color+'-500 text-'+color+'-500'
                style += ' hover:border-'+color+'-600 hover:text-'+color+'-600'
            }else {
                style = ' bg-'+color+'-500'
                style += ' hover:bg-'+color+'-600'
            }

            return style
        }
    }
}
</script>