<template>
    <transition-group name="shuffle" tag="div" class="mx-auto md:h-full md:w-full lg:h-128 lg:w-128 grid grid-cols-8 grid-rows-8 gap-2">
        <div v-for="(block, index) in patterns[patternIndex]" :key="index" :class="block" class="border border-green-500 transition duration-200 ease-out"></div>
    </transition-group>
</template>

<script>
export default {
    name: 'ShufflingPatternBlocks',
    inject: ['data'],
    data(){
        return {
            patternIndex: 0,
        }
    },
    computed: {
        patterns(){
            return this.data.patterns
        }
    },
    mounted(){
        this.shuffle()
    },
    methods: {
        shuffle() {
            // console.log(">> shuffle", this.data);
            // TODO write space allocation n pattern generation algorithm
            setInterval(() => {
                // swap classes
                this.patternIndex = Math.floor(Math.random() * this.patterns.length);
            }, 1500);
        },
    }

}
</script>


<style scoped>

.shuffle-move {
    transition: transform 0.3s ease-out;
}

</style>