<template>
    <div class="mb-10 relative">
        <div @click="resetCountry" class="group cursor-pointer flex items-center justify-between pb-2">
            <div class="flex items-center gap-3 w-full">
                <span :class="[country ? 'group-hover:text-green-700 text-green-600' : 'group-hover:text-gray-700 text-gray-600']">
                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </span>

                <input
                    type="text"
                    autocomplete="off"
                    spellcheck="false"
                    inputmode="search"
                    name="country"
                    v-model="country"
                    placeholder="ENTER COUNTRY"
                    class="cursor-pointer bg-transparent w-full uppercase font-black text-xl focus:outline-none outline-none p-0 caret-green-500"
                    :class="[country ? 'group-hover:text-green-700 text-green-600' : 'group-hover:text-gray-700 text-gray-600']"
                />
            </div>
            <span class="text-green-500 group-hover:text-green-600">
                <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
            </span>
        </div>
        <!-- Suggestion block -->
        <transition-group
            name="slide-down"
            tag="ul"
            appear
            class="absolute bottom-12 md:bottom-auto shadow overflow-y-scroll flex-col gap-1 w-full bg-green-500 text-white font-black text-lg uppercase border border-green-500"
        >
            <li v-for="(country, index) in countrySuggestions" @click="setCountry(country)" :key="index" class="p-2 cursor-pointer hover:bg-green-600">
                {{ country }}
            </li>
        </transition-group>
    </div>
</template>

<script>
export default {
    name: 'CountryInput',
    inject: ['ss', 'data'],
    data(){
        return {
            country: null
        }
    },
    mounted(){
        this.randomizeCountry()
    },
    computed: {
        countries(){
            return this.data.countries
        },
        
        
        countrySuggestions() {
            if (!this.country) {
                return [];
            }
            if (this.countries.find((c) => c == this.country)) {
                return [];
            }
            var countries = this.countries.filter((c) => c.toLowerCase().startsWith(this.country.toLowerCase()));
            if (countries) {
                if (countries.length > 3) countries.splice(2, countries.length - 3)
                return countries
            }
            return []
        },
    },
    methods: {
        randomizeCountry() {
            const country = this.data.countries[Math.floor(Math.random() * this.data.countries.length)];
            this.setCountry(country)
        },
        resetCountry(){
            this.country = null
        },
        setCountry(country){
            this.country = country
            this.ss.methods.setCountry(country)
        },
    }
}
</script>