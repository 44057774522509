<template>
    <div class="fixed top-0 bottom-0 flex justify-end w-full h-full bg-black">
        <div class="grid grid-flow-row uppercase font-black p-5  w-2/3 md:w-1/2 lg:w-1/3 xl:w-2/5 h-full bg-gray-800">
            <div class=" mb-5 text-white flex items-center justify-between">
                <h3>Select Word</h3>
                <span @click="$emit('close')" class="cursor-pointer text-green-500">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </span>
            </div>
            <div class="overflow-y-scroll text-left text-3xl uppercase text-green-500 font-black">
                <div
                    @click="selectWord(word)"
                    v-for="(word, index) in words"
                    :key="index"
                    class="mb-2 cursor-pointer transition-transform ease-out duration-200 transform hover:translate-x-5"
                >
                    {{ word }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WordOptionsModal',
    inject: ['data', 'ss'],
    computed: {
        words(){
            return this.data.words
        }
    },
    methods: {
        selectWord(word){
            this.ss.methods.selectWord(word)
        }
    }
}
</script>