<template>
    <div class="mb-10 relative">
        <div @click="resetName" class="group cursor-pointer flex items-center justify-between pb-2">
            <div class="flex items-center gap-3 w-full">
                <span :class="[name ? 'group-hover:text-green-700 text-green-600' : 'group-hover:text-gray-700 text-gray-600']">
                    <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                </span>

                <input
                    type="text"
                    autocomplete="off"
                    spellcheck="false"
                    inputmode="search"
                    name="name"
                    @keyup="setName(name)"
                    v-model="name"
                    placeholder="YOUR NAME"
                    class="cursor-pointer bg-transparent w-full uppercase font-black text-xl focus:outline-none outline-none p-0 caret-green-500"
                    :class="[name ? 'group-hover:text-green-700 text-green-600' : 'group-hover:text-gray-700 text-gray-600']"
                />
            </div>
            <span class="text-green-500 group-hover:text-green-600">
                <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
            </span>
        </div>
        <!-- Suggestion block -->
        <!-- <transition-group
            name="slide-down"
            tag="ul"
            appear
            class="absolute bottom-12 md:bottom-auto shadow overflow-y-scroll flex-col gap-1 w-full bg-green-500 text-white font-black text-lg uppercase border border-green-500"
        >
            <li v-for="(name, index) in nameSuggestions" @click="setName(name)" :key="index" class="p-2 cursor-pointer hover:bg-green-600">
                {{ name }}
            </li>
        </transition-group> -->
    </div>
</template>

<script>
export default {
    name: 'NameInput',
    inject: ['ss', 'data'],
    data(){
        return {
            name: null
        }
    },
    mounted(){
        // this.randomizeName()
    },
    computed: {
        names(){
            return this.ss.messages.map((m) => m.name)
        },
        
        nameSuggestions() {
            if (!this.name) {
                return [];
            }
            if (this.names.find((c) => c == this.name)) {
                return [];
            }
            var names = this.names.filter((c) => c.toLowerCase().startsWith(this.name.toLowerCase()));
            if (names) {
                if (names.length > 3) names.splice(2, names.length - 3)
                return names
            }
            return []
        },
    },
    methods: {
        randomizeName() {
            const name = this.names[Math.floor(Math.random() * this.names.length)];
            this.setName(name)
        },
        resetName(){
            this.name = null
        },
        setName(name){
            this.name = name
            this.ss.methods.setName(name)
        },
    }
}
</script>