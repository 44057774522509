<template>
    <transition name="fade-in-delay">
        <div :class="classStyle">
            <!-- Pattern grids -->
            <!-- center -->
            <div v-if="showPattern('center')" class="grid grid-cols-4 grid-rows-4 w-full h-full p-2 gap-1 border-4 border-white bg-black">
                <div
                    @click="selectCorner('tl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tl']">select</span>
                    <img v-else :src="patternCorners['tl']" alt="" />
                </div>
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <div
                    @click="selectCorner('tr')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tr') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tr']">select</span>
                    <img v-else :src="patternCorners['tr']" alt="" />
                </div>
                <!--  -->
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <div
                    @click="openWordOptions"
                    class="col-span-2 row-span-2 bg-black border-4 border-gray-700 text-white flex justify-center items-center text-3xl md:text-5xl cursor-pointer transform hover:scale-105 transition-transform duration-100 ease-out"
                >
                    {{ word }}
                </div>
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <!--  -->
                <div
                    @click="selectCorner('bl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[
                        isCornerSelected('bl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '',
                        patternCorners['bl'] != null ? 'border-none' : '',
                    ]"
                >
                    <span v-if="!patternCorners['bl']">select</span>
                    <img v-else :src="patternCorners['bl']" alt="" />
                </div>
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <div>
                    <img :src="fillerImage()" alt="" />
                </div>
                <div
                    @click="selectCorner('br')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('br') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['br']">select</span>
                    <img v-else :src="patternCorners['br']" alt="" />
                </div>
            </div>
            <!-- Top left, bottom right -->
            <div v-if="showPattern('tl-br')" class="grid grid-cols-4 grid-rows-4 w-full h-full p-2 gap-1 border-4 border-white bg-black">
                <div
                    @click="openWordOptions"
                    class="col-span-2 row-span-2 bg-black  border-4 border-gray-700 text-white flex justify-center items-center text-3xl md:text-5xl cursor-pointer transform hover:scale-105 transition-transform duration-100 ease-out"
                >
                    {{ word }}
                </div>
                <!--  -->
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <!--  -->
                <div
                    @click="selectCorner('tl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tl']">select</span>
                    <img v-else :src="patternCorners['tl']" alt="" />
                </div>
                <div
                    @click="selectCorner('tr')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tr') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tr']">select</span>
                    <img v-else :src="patternCorners['tr']" alt="" />
                </div>
                <!--  -->
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <!--  -->
                <div
                    @click="selectCorner('BR')"
                    class="col-span-2 row-span-2 border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[
                        isCornerSelected('BR') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '',
                        patternCorners['BR'] != null ? 'border-none' : '',
                    ]"
                >
                    <span v-if="!patternCorners['BR']">select</span>
                    <img v-else :src="patternCorners['BR']" alt="" />
                </div>
                
                <!--  -->
                <div
                    @click="selectCorner('bl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[
                        isCornerSelected('bl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '',
                        patternCorners['bl'] != null ? 'border-none' : '',
                    ]"
                >
                    <span v-if="!patternCorners['bl']">select</span>
                    <img v-else :src="patternCorners['bl']" alt="" />
                </div>
                
                <div
                    @click="selectCorner('br')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('br') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['br']">select</span>
                    <img v-else :src="patternCorners['br']" alt="" />
                </div>
            </div>
            <!-- Bottom right, top right -->
            <div v-if="showPattern('br-tr')" class="grid grid-cols-4 grid-rows-4 w-full h-full p-2 gap-1 border-4 border-white bg-black">
                <div
                    @click="selectCorner('tl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tl']">select</span>
                    <img v-else :src="patternCorners['tl']" alt="" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('TR')"
                    class="col-span-2 row-span-2 border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('TR') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['TR']">select</span>
                    <img v-else :src="patternCorners['TR']" alt="" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('tr')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tr') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tr']">select</span>
                    <img v-else :src="patternCorners['tr']" alt="" />
                </div>
                <!--  -->
                <!-- Lower half -->
                <div
                    @click="selectCorner('bl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('bl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['bl']">select</span>
                    <img v-else :src="patternCorners['bl']" alt="" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="openWordOptions"
                    class="col-span-2 row-span-2 bg-black  border-4 border-gray-700 text-white flex justify-center items-center text-3xl md:text-5xl cursor-pointer transform hover:scale-105 transition-transform duration-100 ease-out"
                >
                    {{ word }}
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('br')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('br') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['br']">select</span>
                    <img v-else :src="patternCorners['br']" alt="" />
                </div>
            </div>

            <!-- Top left, bottom left -->
            <div v-if="showPattern('tl-bl')" class="grid grid-cols-4 grid-rows-4 w-full h-full p-2 gap-1 border-4 border-white bg-black">
                <div
                    @click="openWordOptions"
                    class="col-span-2 row-span-2 bg-black  border-4 border-gray-700 text-white flex justify-center items-center text-3xl md:text-5xl cursor-pointer transform hover:scale-105 transition-transform duration-100 ease-out"
                >
                    {{ word }}
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('tl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tl']">select</span>
                    <img v-else :src="patternCorners['tl']" alt="" />
                </div>
                
                
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('tr')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tr') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tr']">select</span>
                    <img v-else :src="patternCorners['tr']" alt="" />
                </div>
                <!--  -->
                <!-- Lower half -->
                <div
                    @click="selectCorner('BL')"
                    class="col-span-2 row-span-2 border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('BL') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['BL']">select</span>
                    <img v-else :src="patternCorners['BL']" alt="" />
                </div>
                
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('bl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('bl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['bl']">select</span>
                    <img v-else :src="patternCorners['bl']" alt="" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('br')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('br') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['br']">select</span>
                    <img v-else :src="patternCorners['br']" alt="" />
                </div>
            </div>

            <!-- Bottom left, top right -->
            <div v-if="showPattern('bl-tr')" class="grid grid-cols-4 grid-rows-4 w-full h-full p-2 gap-1 border-4 border-white bg-black">
                
                <!--  -->
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div
                    @click="selectCorner('tl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tl']">select</span>
                    <img v-else :src="patternCorners['tl']" alt="" />
                </div>
                <!--  -->
                <div
                    @click="selectCorner('TR')"
                    class="col-span-2 row-span-2 border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[
                        isCornerSelected('TR') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '',
                        patternCorners['TR'] != null ? 'border-none' : '',
                    ]"
                >
                    <span v-if="!patternCorners['TR']">select</span>
                    <img v-else :src="patternCorners['TR']" alt="" />
                </div>

                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                
                <div
                    @click="selectCorner('tr')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tr') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tr']">select</span>
                    <img v-else :src="patternCorners['tr']" alt="" />
                </div>

                <!-- Lower half -->
                <div
                    @click="openWordOptions"
                    class="col-span-2 row-span-2 bg-black  border-4 border-gray-700 text-white flex justify-center items-center text-3xl md:text-5xl cursor-pointer transform hover:scale-105 transition-transform duration-100 ease-out"
                >
                    {{ word }}
                </div>
                <!--  -->
                <div
                    @click="selectCorner('bl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[
                        isCornerSelected('bl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '',
                        patternCorners['bl'] != null ? 'border-none' : '',
                    ]"
                >
                    <span v-if="!patternCorners['bl']">select</span>
                    <img v-else :src="patternCorners['bl']" alt="" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                
                <div
                    @click="selectCorner('br')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('br') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['br']">select</span>
                    <img v-else :src="patternCorners['br']" alt="" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
            </div>

            <!-- Top right, Bottom left -->
            <div v-if="showPattern('tr-bl')"  class="grid grid-cols-4 grid-rows-4 w-full h-full p-2 gap-1 border-4 border-white bg-black">
                
                <!--  -->
                
                <div
                    @click="selectCorner('tl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tl']">select</span>
                    <img v-else :src="patternCorners['tl']" alt="" />
                </div>
                <div
                    @click="selectCorner('tr')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('tr') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['tr']">select</span>
                    <img v-else :src="patternCorners['tr']" alt="" />
                </div>
                
                <!--  -->
                <div
                    @click="openWordOptions"
                    class="col-span-2 row-span-2 bg-black  border-4 border-gray-700 text-white flex justify-center items-center text-3xl md:text-5xl cursor-pointer transform hover:scale-105 transition-transform duration-100 ease-out"
                >
                    {{ word }}
                </div>
            
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                

                <!-- Lower half -->
                
                <div
                    @click="selectCorner('BL')"
                    class="col-span-2 row-span-2 border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[
                        isCornerSelected('BL') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '',
                        patternCorners['BL'] != null ? 'border-none' : '',
                    ]"
                >
                    <span v-if="!patternCorners['BL']">select</span>
                    <img v-else :src="patternCorners['BL']" alt="" />
                </div>
                <!--  -->
                <div
                    @click="selectCorner('bl')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[
                        isCornerSelected('bl') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '',
                        patternCorners['bl'] != null ? 'border-none' : '',
                    ]"
                >
                    <span v-if="!patternCorners['bl']">select</span>
                    <img v-else :src="patternCorners['bl']" alt="" />
                </div>
                
                
                
                <div
                    @click="selectCorner('br')"
                    class="border-4 border-dotted border-gray-700 flex justify-center items-center text-gray-700 hover:bg-gray-900 hover:text-gray-600 cursor-pointer"
                    :class="[isCornerSelected('br') ? 'border-green-400 text-green-400 hover:border-green-500 hover:text-green-500' : '']"
                >
                    <span v-if="!patternCorners['br']">select</span>
                    <img v-else :src="patternCorners['br']" alt="" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
                <div class="grid grid-cols-2 grid-rows-2">
                    <img v-for="i in 4" :key="i" :src="fillerImage()" alt="" class="border-2 border-black" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "MessagePatternGrid",
    props: ['classStyle'],
    inject: ['ss',],
    data() {
        return {
            fillerIndex: 0,
        }
    },
    mounted(){
        this.randomizeFiller()

    },
    computed: {
        patternCorners(){ return this.ss.state.patternCorners},
        selectedPatternOption(){
            return this.ss.getters.selectedPatternOption()
        },
        word(){ return this.ss.state.word},
    },
    methods: {
        fillerImage(){
            return this.ss.getters.fillerImages()[this.fillerIndex]
        },
        isCornerSelected(corner){
            return this.ss.state.selectedCorner == corner
        },
        showPattern(name){
            return this.selectedPatternOption.name == name
        },
        openWordOptions(){
            this.ss.methods.openWordOptions()
        },
        randomizeFiller(){
            this.fillerIndex = Math.floor(Math.random() * this.ss.getters.fillerImages().length)
            this.ss.appLog(">>fillerImage", this.fillerIndex);
        },
        selectCorner(corner){
            this.ss.methods.selectCorner(corner)
            this.$emit('cornerSelected')
        },
        
    }
};
</script>
